<template>
  <div
    class="search pa-4"
    :class="{ 'column-gap': !hideDocumentTabs }"
  >
    <search-toolbar
      class="toolbar"
      :use-responsive-search-field="useResponsiveSearchField"
      :search="search"
    />
    <search-documents
      v-if="!hideDocumentTabs"
      class="documents"
      :search="search"
    />
    <div class="list d-flex flex-column overflow-hidden">
      <search-list
        :search="search"
        @row-action="$emit('row-action', $event)"
      />
      <v-pagination
        v-if="search.pageCount > 1"
        color="primary"
        :length="search.pageCount"
        total-visible=9
        v-model="page"
      />
    </div>
    <document-preview-popup
      v-model="search.showPreviewPopup"
      :document="search.previewDocument"
    />
  </div>
</template>

<script>
import Search, { GlobalSearch } from '../controllers'

export default {
  name: 'search',
  components: {
    DocumentPreviewPopup: () => import('@/pages/search/components/document-preview-popup'),
    SearchDocuments: () => import('./search-documents'),
    SearchList: () => import('./search-list'),
    SearchToolbar: () => import('./search-toolbar')
  },
  activated () {
    if (!this.lazy) {
      this.search.refreshResults()
    }
    this.registerToGlobalSearch()
  },
  beforeDestroy () {
    this.search = undefined
    this.unregisterOfGlobalSearch()
  },
  computed: {
    page: {
      get () {
        return this.search.page + 1
      },
      set (page) {
        this.search.setPage(page).execute()
      }
    },
    scopeModes () {
      return []
    }
  },
  data () {
    return {
      search: new Search(Search.actions.GRID).setLinkTarget(this.linkTarget)
    }
  },
  deactivated () {
    this.unregisterOfGlobalSearch()
  },
  mounted () {
    this.registerToGlobalSearch()
    this.search.searchedDocumentTypes.include([
      'business-divisions',
      'escalation-protocols'
    ])

    this.search.setScope(this.scope)

    if (!this.lazy) {
      this.search.execute()
    }
  },
  methods: {
    registerToGlobalSearch () {
      if (this.scopeModes.length) {
        GlobalSearch.register(this.search, this.scopeModes)
      }
    },
    unregisterOfGlobalSearch () {
      if (this.scopeModes.length) {
        GlobalSearch.unregister(this.search)
      }
    }
  },
  watch: {
    hideActions: {
      handler (v) {
        this.search.disableItemSelection(v)
      },
      immediate: true
    }
  },
  props: {
    hideActions: Boolean,
    hideDocumentTabs: Boolean,
    lazy: Boolean,
    linkTarget: Object,
    scope: Search.typeOfScope,
    useResponsiveSearchField: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.search
  flex-grow 1
  display grid
  grid-template 'documents toolbar' 'documents list' 1fr / auto 1fr
  row-gap 16px

.column-gap
  column-gap 16px

.toolbar
  grid-area toolbar

.documents
  grid-area documents
  flex-grow 0

.list
  grid-area list
</style>
